export enum SubcategoryEnum {
  ADVANCED_VEHICLE_FUELING_INFRASTRUCTURE = 'ADVANCED_VEHICLE_FUELING_INFRASTRUCTURE',
  ADVANCED_RENEWABLE_HEATING_FUELS = 'ADVANCED_RENEWABLE_HEATING_FUELS',
  AGRICULTURE = 'AGRICULTURE',
  APPLIANCE_EQUIPMENT_STANDARDS = 'APPLIANCE_EQUIPMENT_STANDARDS',
  AUTONOMOUS_VEHICLES = 'AUTONOMOUS_VEHICLES',
  BIG_DATA = 'BIG_DATA',
  BIOMASS_BIOFUEL = 'BIOMASS_BIOFUEL',
  BROWNFIELDS = 'BROWNFIELDS',
  BUILDINGS = 'BUILDINGS',
  CARBON_CAPTURE_AND_SEQUESTRATION_CCS = 'CARBON_CAPTURE_AND_SEQUESTRATION_CCS',
  CARBON_MARKETS = 'CARBON_MARKETS',
  CARBON_PRICING = 'CARBON_PRICING',
  CELA = 'CELA',
  CLEAN_HEAT_STANDARD = 'CLEAN_HEAT_STANDARD',
  CLEAN_PEAK_STANDARD = 'CLEAN_PEAK_STANDARD',
  CLIMATE_ADAPTATION = 'CLIMATE_ADAPTATION',
  CLIMATE = 'CLIMATE',
  CYBERSECURITY = 'CYBERSECURITY',
  DISTRIBUTED_GENERATION = 'DISTRIBUTED_GENERATION',
  ELECTRIC_GENERATION = 'ELECTRIC_GENERATION',
  ELECTRIFICATION = 'ELECTRIFICATION',
  EMISSIONS = 'EMISSIONS',
  ENERGY_COST_ASSISTANCE = 'ENERGY_COST_ASSISTANCE',
  ENERGY_DISTRIBUTION = 'ENERGY_DISTRIBUTION',
  ENERGY_EFFICIENCY = 'ENERGY_EFFICIENCY',
  ENERGY_TAXES_FEES = 'ENERGY_TAXES_FEES',
  ENERGY_USE_DISCLOSURE = 'ENERGY_USE_DISCLOSURE',
  ENVIRONMENTAL_JUSTICE = 'ENVIRONMENTAL_JUSTICE',
  EQUITY = 'EQUITY',
  EVS = 'EVS',
  FUEL_CELL = 'FUEL_CELL',
  FUEL_TAX = 'FUEL_TAX',
  GEOTHERMAL = 'GEOTHERMAL',
  GETS = 'GETS',
  HEALTH_IMPACTS = 'HEALTH_IMPACTS',
  HEAT_PUMPS = 'HEAT_PUMPS',
  HFCS = 'HFCS',
  HYDROGEN = 'HYDROGEN',
  HYDROPOWER = 'HYDROPOWER',
  INTEGRATED_LONG_TERM_RESOURCE_PLANNING = 'INTEGRATED_LONG_TERM_RESOURCE_PLANNING',
  LABOR_PROTECTIONS = 'LABOR_PROTECTIONS',
  LEED_GREEN_BUILDING = 'LEED_GREEN_BUILDING',
  LEV = 'LEV',
  LOANS_AND_GRANTS = 'LOANS_AND_GRANTS',
  LOW_CARBON_CLEAN_FUEL_STANDARD = 'LOW_CARBON_CLEAN_FUEL_STANDARD',
  LOW_INCOME_RENEWABLE_ENERGY = 'LOW_INCOME_RENEWABLE_ENERGY',
  MARINE_ENERGY = 'MARINE_ENERGY',
  MEDIUM_AND_HEAVY_DUTY_VEHICLES = 'MEDIUM_AND_HEAVY_DUTY_VEHICLES',
  METHANE_OIL_GAS = 'METHANE_OIL_GAS',
  MICROGRID = 'MICROGRID',
  MICROMOBILITY = 'MICROMOBILITY',
  NUCLEAR = 'NUCLEAR',
  OFFSHORE_WIND = 'OFFSHORE_WIND',
  OMNIBUS = 'OMNIBUS',
  PIPELINE = 'PIPELINE',
  RARE_EARTH_MINERALS = 'RARE_EARTH_MINERALS',
  RATES = 'RATES',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  RTO_ISO = 'RTO_ISO',
  SECURITIZATION = 'SECURITIZATION',
  SEVERANCE_PRODUCTION_TAXES = 'SEVERANCE_PRODUCTION_TAXES',
  SITING = 'SITING',
  SMART_GRID = 'SMART_GRID',
  SMART_METERS = 'SMART_METERS',
  SOLAR = 'SOLAR',
  STORAGE = 'STORAGE',
  STUDIES = 'STUDIES',
  SYSTEMS_BENEFIT_CHARGE = 'SYSTEMS_BENEFIT_CHARGE',
  TAX_INCENTIVES = 'TAX_INCENTIVES',
  TRANSITION = 'TRANSITION',
  TRANSMISSION = 'TRANSMISSION',
  TRANSPORTATION = 'TRANSPORTATION',
  TRIBES = 'TRIBES',
  VMT_VMT_FEE = 'VMT_VMT_FEE',
  WILDFIRES = 'WILDFIRES',
  WIND = 'WIND',
  WORKFORCE_DEVELOPMENT = 'WORKFORCE_DEVELOPMENT',
  ZEV = 'ZEV',
}

export enum LegacySubcategoryEnum {
  BIOFUEL = 'BIOFUEL',
  BIOMASS = 'BIOMASS',
  BONDS = 'BONDS',
  COMMISSION_MEMBERSHIP = 'COMMISSION_MEMBERSHIP',
  CONNECTING = 'CONNECTING',
  DISCLOSURE = 'DISCLOSURE',
  DISTRIBUTED_SOLAR_GENERATION = 'DISTRIBUTED_SOLAR_GENERATION',
  ECONOMIC_DEVELOPMENT = 'ECONOMIC_DEVELOPMENT',
  ELECTRICITY_GENERATION = 'ELECTRICITY_GENERATION',
  ENERGY_STAR_APPLIANCES = 'ENERGY_STAR_APPLIANCES',
  FINANCING = 'FINANCING',
  FRACKING = 'FRACKING',
  GRANT_PROGRAM = 'GRANT_PROGRAM',
  GREEN_JOBS = 'GREEN_JOBS',
  IMPACTS = 'IMPACTS',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  JOB_SKILLS_TRAINING = 'JOB_SKILLS_TRAINING',
  LANDOWNER_EFFECTS = 'LANDOWNER_EFFECTS',
  LEAD_BY_EXAMPLE = 'LEAD_BY_EXAMPLE',
  LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY = 'LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY',
  LOANS = 'LOANS',
  LOAN_PROGRAM = 'LOAN_PROGRAM',
  LOW_INCOME_ENERGY_ASSISTANCE = 'LOW_INCOME_ENERGY_ASSISTANCE',
  METERING = 'METERING',
  METERS = 'METERS',
  METHANE = 'METHANE',
  MINERAL_ESTATE = 'MINERAL_ESTATE',
  MODIFY = 'MODIFY',
  MORATORIUM = 'MORATORIUM',
  NATURAL_GAS = 'NATURAL_GAS',
  NATURAL_GAS_DEVELOPMENT = 'NATURAL_GAS_DEVELOPMENT',
  NET_METERING = 'NET_METERING',
  NEW_OFFICE = 'NEW_OFFICE',
  NOTICE = 'NOTICE',
  OTHER_ENERGY = 'OTHER_ENERGY',
  OVERSIGHT = 'OVERSIGHT',
  PACE = 'PACE',
  POOLING = 'POOLING',
  PROCEDURAL_DIRECTION = 'PROCEDURAL_DIRECTION',
  PRODUCTION_BY_PRODUCTS = 'PRODUCTION_BY_PRODUCTS',
  PURCHASING = 'PURCHASING',
  REGULATORY = 'REGULATORY',
  RENEWABLES = 'RENEWABLES',
  RENEWABLE_PORTFOLIO_STANDARD = 'RENEWABLE_PORTFOLIO_STANDARD',
  RESOURCE_PLANNING = 'RESOURCE_PLANNING',
  RPS = 'RPS',
  SALES_TAX = 'SALES_TAX',
  SBC = 'SBC',
  SEVERANCE_TAXES = 'SEVERANCE_TAXES',
  SPLIT_ESTATE = 'SPLIT_ESTATE',
  STATE_PRE_EMPTION = 'STATE_PRE_EMPTION',
  TAXATION = 'TAXATION',
  TAX_CREDITS = 'TAX_CREDITS',
  THIRD_PARTY_LEASING = 'THIRD_PARTY_LEASING',
  TIDAL = 'TIDAL',
  TOLL_DISCOUNT = 'TOLL_DISCOUNT',
  UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION = 'UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION',
  WATER = 'WATER',
  WIND_HYDROPOWER = 'WIND_HYDROPOWER',
}
