import { gql } from '@apollo/client';

export const GET_ADMIN_CELA_BILLS = gql`
  query(
  $inYearsFiled: [Int!]
  $inOsStates: [String!]
  $inOsStatuses: [String!]
  $likePolicyCategories: [String!]
  $likeKeywords: String
  $sortColumn: String
  $sortDirection: SortDirection
  $pageNumber: Int
  $pageSize: Int
  ) {
    listActiveCelaBills: bills(
      input: {
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        pageNumber: $pageNumber,
        pageSize: $pageSize,
        cela: true,
      }
    ) {
      billId
      title
      cela
      state
      number
      yearFiled
      autoTimeOsUpdate
      chamberName
      osBillId
      osState
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      policyCategory
      subcategory
      topic
      summary
      osCreatedAt
      sponsorships {
        id
        type
        order
        person {
          id
          name
          party
          firstName
          lastName
        }
      }
    },
    policyDistribution: billsDistribution(
      input: {
        cela: true,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "policyCategory"
      }
    ) {
      group
      count
    },
    statusDistribution: billsDistribution(
      input: {
        cela: true,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "osStatus"
      }
    ) {
      group
      count
    },
    stateDistribution: billsDistribution(
      input: {
        cela: true,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "state"
      }
    ) {
      group
      count
    },
    billsCount(
      input: {
        cela: true,
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        groupBy: "state"
      }
    )
  }
`;
