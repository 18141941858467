import { gql } from '@apollo/client';

export const ADD_BILL_MYTRACKER = gql`
  mutation ($userId: Int!, $billId: Int!) {
    addTrackedBill(userId: $userId, billId: $billId) {
      id
      billId
      autoTime
    }
  }
`;

export const USER_TRACKER_BILLS_QUERY = gql`
  query(
  $inYearsFiled: [Int!]
  $inOsStates: [String!]
  $inOsStatuses: [String!]
  $likePolicyCategories: [String!]
  $likeKeywords: String
  $sortColumn: String
  $sortDirection: SortDirection
  $pageNumber: Int
  $pageSize: Int 
  $user: Int!
  ) {
    userBills: bills(
      input: {
        user: $user,
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        pageNumber: $pageNumber,
        pageSize: $pageSize,
      }
    ) {
      billId
      title
      cela
      state
      number
      yearFiled
      summary
      autoTimeOsUpdate
      chamberName
      policyCategory
      osBillId
      osState
      osDatePassedLower
      osDatePassedUpper
      osDateSigned
      osStatus
      osStatusId
      subcategory
      topic
      osCreatedAt
      sponsorships {
        id
        type
        order
        person {
          id
          name
          party
          firstName
          lastName
        }
      }
    },
    policyDistribution: billsDistribution(
      input: {
        user: $user,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "policyCategory"
      }
    ) {
      group
      count
    },
    statusDistribution: billsDistribution(
      input: {
        user: $user,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,        
        groupBy: "osStatus"
      }
    ) {
      group
      count
    },
    stateDistribution: billsDistribution(
      input: {
        user: $user,
        inYearsFiled: $inYearsFiled,
        inOsStates: $inOsStates,
        inOsStatuses: $inOsStatuses,
        likePolicyCategories: $likePolicyCategories,
        likeKeywords: $likeKeywords,
        groupBy: "state"
      }
    ) {
      group
      count
    },
    billsCount(
      input: {
        user: $user,
        inYearsFiled: $inYearsFiled
        inOsStates: $inOsStates
        inOsStatuses: $inOsStatuses
        likePolicyCategories: $likePolicyCategories
        likeKeywords: $likeKeywords
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        groupBy: "state"
      }
    )

  }
`
