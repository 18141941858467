import { LegacySubcategoryEnum, SubcategoryEnum } from 'src/types/Subcategory.enum';

export const subcategoryText = {
  [SubcategoryEnum.ADVANCED_VEHICLE_FUELING_INFRASTRUCTURE]: 'Advanced Vehicle Fueling Infrastructure',
  [SubcategoryEnum.ADVANCED_RENEWABLE_HEATING_FUELS]: 'Advanced Renewable Heating Fuels',
  [SubcategoryEnum.AGRICULTURE]: 'Agriculture',
  [SubcategoryEnum.APPLIANCE_EQUIPMENT_STANDARDS]: 'Appliance/Equipment Standards',
  [SubcategoryEnum.AUTONOMOUS_VEHICLES]: 'Autonomous Vehicles',
  [SubcategoryEnum.BIG_DATA]: 'Big Data',
  [SubcategoryEnum.BIOMASS_BIOFUEL]: 'Biomass/Biofuel',
  [SubcategoryEnum.BROWNFIELDS]: 'Brownfields',
  [SubcategoryEnum.BUILDINGS]: 'Buildings',
  [SubcategoryEnum.CARBON_CAPTURE_AND_SEQUESTRATION_CCS]: 'Carbon Capture and Sequestration/CCS',
  [SubcategoryEnum.CARBON_MARKETS]: 'Carbon Markets',
  [SubcategoryEnum.CARBON_PRICING]: 'Carbon Pricing',
  [SubcategoryEnum.CELA]: 'CELA',
  [SubcategoryEnum.CLEAN_HEAT_STANDARD]: 'Clean Heat Standard',
  [SubcategoryEnum.CLEAN_PEAK_STANDARD]: 'Clean Peak Standard',
  [SubcategoryEnum.CLIMATE_ADAPTATION]: 'Climate Adaptation',
  [SubcategoryEnum.CLIMATE]: 'Climate',
  [SubcategoryEnum.CYBERSECURITY]: 'Cybersecurity',
  [SubcategoryEnum.DISTRIBUTED_GENERATION]: 'Distributed Generation',
  [SubcategoryEnum.ELECTRIC_GENERATION]: 'Electric Generation',
  [SubcategoryEnum.ELECTRIFICATION]: 'Electrification',
  [SubcategoryEnum.EMISSIONS]: 'Emissions',
  [SubcategoryEnum.ENERGY_COST_ASSISTANCE]: 'Energy Cost Assistance',
  [SubcategoryEnum.ENERGY_DISTRIBUTION]: 'Energy Distribution',
  [SubcategoryEnum.ENERGY_EFFICIENCY]: 'Energy Efficiency',
  [SubcategoryEnum.ENERGY_TAXES_FEES]: 'Energy Taxes/Fees',
  [SubcategoryEnum.ENERGY_USE_DISCLOSURE]: 'Energy Use Disclosure',
  [SubcategoryEnum.ENVIRONMENTAL_JUSTICE]: 'Environmental Justice',
  [SubcategoryEnum.EQUITY]: 'Equity',
  [SubcategoryEnum.EVS]: 'EVs',
  [SubcategoryEnum.FUEL_CELL]: 'Fuel Cell',
  [SubcategoryEnum.FUEL_TAX]: 'Fuel Tax',
  [SubcategoryEnum.GEOTHERMAL]: 'Geothermal',
  [SubcategoryEnum.GETS]: 'GETs',
  [SubcategoryEnum.HEALTH_IMPACTS]: 'Health Impacts',
  [SubcategoryEnum.HEAT_PUMPS]: 'Heat Pumps',
  [SubcategoryEnum.HFCS]: 'HFCs',
  [SubcategoryEnum.HYDROGEN]: 'Hydrogen',
  [SubcategoryEnum.HYDROPOWER]: 'Hydropower',
  [SubcategoryEnum.INTEGRATED_LONG_TERM_RESOURCE_PLANNING]: 'Integrated/Long-Term Resource Planning',
  [SubcategoryEnum.LABOR_PROTECTIONS]: 'Labor Protections',
  [SubcategoryEnum.LEED_GREEN_BUILDING]: 'LEED / Green Building',
  [SubcategoryEnum.LEV]: 'LEV',
  [SubcategoryEnum.LOANS_AND_GRANTS]: 'Loans and Grants',
  [SubcategoryEnum.LOW_CARBON_CLEAN_FUEL_STANDARD]: 'Low Carbon/Clean Fuel Standard',
  [SubcategoryEnum.LOW_INCOME_RENEWABLE_ENERGY]: 'Low Income Renewable Energy',
  [SubcategoryEnum.MARINE_ENERGY]: 'Marine Energy',
  [SubcategoryEnum.MEDIUM_AND_HEAVY_DUTY_VEHICLES]: 'Medium- and Heavy-Duty Vehicles',
  [SubcategoryEnum.METHANE_OIL_GAS]: 'Methane (Oil & Gas)',
  [SubcategoryEnum.MICROGRID]: 'Microgrid',
  [SubcategoryEnum.MICROMOBILITY]: 'Micromobility',
  [SubcategoryEnum.NUCLEAR]: 'Nuclear',
  [SubcategoryEnum.OFFSHORE_WIND]: 'Offshore Wind',
  [SubcategoryEnum.OMNIBUS]: 'Omnibus',
  [SubcategoryEnum.PIPELINE]: 'Pipeline',
  [SubcategoryEnum.RARE_EARTH_MINERALS]: 'Rare Earth Minerals',
  [SubcategoryEnum.RATES]: 'Rates',
  [SubcategoryEnum.RESEARCH_AND_DEVELOPMENT]: 'Research and Development',
  [SubcategoryEnum.RTO_ISO]: 'RTO/ISO',
  [SubcategoryEnum.SECURITIZATION]: 'Securitization',
  [SubcategoryEnum.SEVERANCE_PRODUCTION_TAXES]: 'Severance/ Production Taxes',
  [SubcategoryEnum.SITING]: 'Siting',
  [SubcategoryEnum.SMART_GRID]: 'Smart Grid',
  [SubcategoryEnum.SMART_METERS]: 'Smart Meters',
  [SubcategoryEnum.SOLAR]: 'Solar',
  [SubcategoryEnum.STORAGE]: 'Storage',
  [SubcategoryEnum.STUDIES]: 'Studies',
  [SubcategoryEnum.SYSTEMS_BENEFIT_CHARGE]: 'Systems Benefit Charge',
  [SubcategoryEnum.TAX_INCENTIVES]: 'Tax Incentives',
  [SubcategoryEnum.TRANSITION]: 'Transition',
  [SubcategoryEnum.TRANSMISSION]: 'Transmission',
  [SubcategoryEnum.TRANSPORTATION]: 'Transportation',
  [SubcategoryEnum.TRIBES]: 'Tribes',
  [SubcategoryEnum.VMT_VMT_FEE]: 'VMT / VMT Fee',
  [SubcategoryEnum.WILDFIRES]: 'Wildfires',
  [SubcategoryEnum.WIND]: 'Wind',
  [SubcategoryEnum.WORKFORCE_DEVELOPMENT]: 'Workforce Development',
  [SubcategoryEnum.ZEV]: 'ZEV',
};

export const legacySubcategoryText = {
  [LegacySubcategoryEnum.BIOFUEL]: 'Biofuel',
  [LegacySubcategoryEnum.BIOMASS]: 'Biomass',
  [LegacySubcategoryEnum.BONDS]: 'Bonds',
  [LegacySubcategoryEnum.COMMISSION_MEMBERSHIP]: 'Commission Membership',
  [LegacySubcategoryEnum.CONNECTING]: 'Connecting',
  [LegacySubcategoryEnum.DISCLOSURE]: 'Disclosure',
  [LegacySubcategoryEnum.DISTRIBUTED_SOLAR_GENERATION]: 'Distributed Solar Generation',
  [LegacySubcategoryEnum.ECONOMIC_DEVELOPMENT]: 'Economic Development',
  [LegacySubcategoryEnum.ELECTRICITY_GENERATION]: 'Electricity Generation',
  [LegacySubcategoryEnum.ENERGY_STAR_APPLIANCES]: 'Energy Star Appliances',
  [LegacySubcategoryEnum.FINANCING]: 'Financing',
  [LegacySubcategoryEnum.FRACKING]: 'Fracking',
  [LegacySubcategoryEnum.GRANT_PROGRAM]: 'Grant Program',
  [LegacySubcategoryEnum.GREEN_JOBS]: 'Green Jobs',
  [LegacySubcategoryEnum.IMPACTS]: 'Impacts',
  [LegacySubcategoryEnum.INFRASTRUCTURE]: 'Infrastructure',
  [LegacySubcategoryEnum.JOB_SKILLS_TRAINING]: 'Job/Skills Training',
  [LegacySubcategoryEnum.LANDOWNER_EFFECTS]: 'Land-Owner Effects',
  [LegacySubcategoryEnum.LEAD_BY_EXAMPLE]: 'Lead by Example',
  [LegacySubcategoryEnum.LEASING_ADJUSTMENTS_FOR_STATE_PROPERTY]: 'Leasing Adjustments for State Property',
  [LegacySubcategoryEnum.LOANS]: 'Loans',
  [LegacySubcategoryEnum.LOAN_PROGRAM]: 'Loan Program',
  [LegacySubcategoryEnum.LOW_INCOME_ENERGY_ASSISTANCE]: 'Low-Income Energy Assistance',
  [LegacySubcategoryEnum.METERING]: 'Metering',
  [LegacySubcategoryEnum.METERS]: 'Meters',
  [LegacySubcategoryEnum.METHANE]: 'Methane',
  [LegacySubcategoryEnum.MINERAL_ESTATE]: 'Mineral Estate',
  [LegacySubcategoryEnum.MODIFY]: 'Modify',
  [LegacySubcategoryEnum.MORATORIUM]: 'Moratorium',
  [LegacySubcategoryEnum.NATURAL_GAS]: 'Natural Gas',
  [LegacySubcategoryEnum.NATURAL_GAS_DEVELOPMENT]: 'Natural Gas Development',
  [LegacySubcategoryEnum.NET_METERING]: 'Net Metering',
  [LegacySubcategoryEnum.NEW_OFFICE]: 'New Office',
  [LegacySubcategoryEnum.NOTICE]: 'Notice',
  [LegacySubcategoryEnum.OTHER_ENERGY]: 'Other Energy',
  [LegacySubcategoryEnum.OVERSIGHT]: 'Oversight',
  [LegacySubcategoryEnum.PACE]: 'PACE',
  [LegacySubcategoryEnum.POOLING]: 'Pooling',
  [LegacySubcategoryEnum.PROCEDURAL_DIRECTION]: 'Procedural Direction',
  [LegacySubcategoryEnum.PRODUCTION_BY_PRODUCTS]: 'Production By-Products',
  [LegacySubcategoryEnum.PURCHASING]: 'Purchasing',
  [LegacySubcategoryEnum.REGULATORY]: 'Regulatory',
  [LegacySubcategoryEnum.RENEWABLES]: 'Renewables',
  [LegacySubcategoryEnum.RENEWABLE_PORTFOLIO_STANDARD]: 'Renewable Portfolio Standard',
  [LegacySubcategoryEnum.RESOURCE_PLANNING]: 'Resource Planning',
  [LegacySubcategoryEnum.RPS]: 'RPS',
  [LegacySubcategoryEnum.SALES_TAX]: 'Sales Tax',
  [LegacySubcategoryEnum.SBC]: 'SBC',
  [LegacySubcategoryEnum.SEVERANCE_TAXES]: 'Severance Taxes',
  [LegacySubcategoryEnum.SPLIT_ESTATE]: 'Split Estate',
  [LegacySubcategoryEnum.STATE_PRE_EMPTION]: 'State Pre-emption',
  [LegacySubcategoryEnum.TAXATION]: 'Taxation',
  [LegacySubcategoryEnum.TAX_CREDITS]: 'Tax Credits',
  [LegacySubcategoryEnum.THIRD_PARTY_LEASING]: 'Third-party Leasing',
  [LegacySubcategoryEnum.TIDAL]: 'Tidal',
  [LegacySubcategoryEnum.TOLL_DISCOUNT]: 'Toll Discount',
  [LegacySubcategoryEnum.UTILITY_PAYMENT_FOR_ELECTRICITY_GENERATION]: 'Utility Payment for Electricity Generation',
  [LegacySubcategoryEnum.WATER]: 'Water',
  [LegacySubcategoryEnum.WIND_HYDROPOWER]: 'Wind/Hydro-power',
};
