import { Dispatch, SetStateAction } from 'react';
import { SortDirectionEnum } from '../types/SortDirection.enum';
import { findSortOption } from 'src/pages/Landing/MainSection/MainSection';
import { FiltersTemplate } from 'src/pages/Landing/FiltersSection/types/FilterTemplate.interface';

interface FilterFunctions {
  handleSort: (v: string) => void;
  clearFilterInputs: () => void;
  handleClear: (id: string) => void;
  handleSelectedValues: (values: string[], id: string) => void;
  handleSearch: (id: string, value: string) => void;
  defaultFilter: FiltersTemplate;
}

export const useFilterHandlers = (
  setOrderBy: Dispatch<SetStateAction<string>>,
  setSortColumn: Dispatch<SetStateAction<string>>,
  setSortDirection: Dispatch<SetStateAction<SortDirectionEnum>>,
  setFilter: Dispatch<SetStateAction<any>>,
  defaultFilter: FiltersTemplate,
): FilterFunctions => {
  return {
    handleSort: (v: string): void => {
      setOrderBy(v);
      const options = findSortOption(v);
      if (options != null) {
        setSortColumn(options.column);
        setSortDirection(options.direction);
      }
    },

    clearFilterInputs: (): void => {
      setFilter(defaultFilter);
    },

    handleClear: (id: string): void => {
      setFilter((prevFilter: any) => ({ ...prevFilter, [id]: [] }));
    },

    handleSelectedValues: (values: string[], id: string): void => {
      setFilter((prevFilter: any) => ({ ...prevFilter, [id]: values }));
    },

    handleSearch: (id: string, value: string): void => {
      setFilter((prevFilter: any) => ({ ...prevFilter, [id]: value }));
    },
    defaultFilter,
  };
};
